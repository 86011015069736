import { createSlice } from "@reduxjs/toolkit";
import { filterOrderByMilestone, sortAllView } from "../utils";

const initialState = {
    boardView: 'false',
    allViews: {
        listView: {
            nameOfViews: [],
            allItemsName: ['TRANSFER NAME', 'ERP SUPPLIER', 'FROM WAREHOUSE', 'TO WAREHOUSE', 'CONSOLIDATION STATUS', 'CREATED BY', 'STATUS'],
            allViews: {
                'Default View': ['ERP SUPPLIER', 'FROM WAREHOUSE', 'TO WAREHOUSE', 'CONSOLIDATION STATUS', 'CREATED BY', 'STATUS'],
            }
        },
        boardView: {
            status: {
                nameOfViews: [],
                allItemsName: ["Draft", "Created", "Not Allocated", "Partially Allocated", "Fully Allocated", "Partially Cargo Ready", "Fully Cargo Ready", "Partially Dispatched", "Fully Dispatched", "Partially Received", "Fully Received", "Partially Receipted", "Fully Receipted", "Completed", "Cancelled"],
                allViews: {
                    'Default View': ['Fully Dispatched', 'Partially Receipted', 'Completed',
                        "Partially Allocated", "Not Allocated"],
                }
            },
            milestone: {
                nameOfViews: [],
                allItemsName: [],
                allViews: {

                }
            }
        },
    },

    singleView: {},
    milestoneOrders: {},
    milestoneList: [],
    filterShipmentByMilestone: {},
    consignmentMappedById: {},

    allFilterField: [],
    allFilterCondition: [],
    filteredConsignmentByRule: null

};

const newTransferOrderSlice = createSlice({
    name: "newTransferOrder",
    initialState,
    reducers: {
        setMilestoneOrdersForTransfer: (state, action) => {
            state.milestoneList = action.payload;
        },
        setAllViewsForTransfer: (state, action) => {
            const data = action.payload;
            const views = sortAllView(data);
            state.allViews = {
                listView: {
                    ...state.allViews.listView,
                    nameOfViews: views.list.nameOfList,
                    allViews: { ...state.allViews.listView.allViews, ...views.list.allViews }
                },
                boardView: {
                    status: {
                        ...state.allViews.boardView.status,
                        nameOfViews: views.status.nameOfList,
                        allViews: { ...state.allViews.boardView.status.allViews, ...views.status.allViews }
                    },
                    milestone: {
                        nameOfViews: views.milestone.nameOfList,
                        allViews: views.milestone.allViews
                    }
                },
                completeListOfViews: action.payload,
            }
        },

        handleFilterTransferByMilestone: (state, action) => {
            const { filterd, mapConsignments } = filterOrderByMilestone(action.payload, state.milestoneList);
            state.consignmentMappedById = mapConsignments;
            state.filterShipmentByMilestone = filterd;
        },
        updateFilterTransferByMilestone: (state, action) => {
            const filterd = { ...state.filterShipmentByMilestone };

            filterd[action.payload.sourceIndex] = filterd[action.payload.sourceIndex].filter(item => item !== action.payload.data.ids[0]);
            filterd[action.payload.id] = [...action.payload.data.ids, ...filterd[action.payload.id]]

            state.filterShipmentByMilestone = filterd;
        },
    },
});

export const {
    setMilestoneOrdersForTransfer,
    setAllViewsForTransfer,
    handleFilterTransferByMilestone,
    updateFilterTransferByMilestone
} = newTransferOrderSlice.actions;

export default newTransferOrderSlice.reducer;
