import { createSlice } from "@reduxjs/toolkit";
import { filterOrderByMilestone, sortAllView } from "../utils";

const initialState = {
    boardView: 'false',
    allViews: {
        listView: {
            nameOfViews: [],
            allItemsName: ['SHIPMENT NAME', 'SUPPLIER', 'FROM WAREHOUSE', 'TO WAREHOUSE', 'ORDER DATE', 'ETA', 'CREATED BY', 'STATUS'],
            allViews: {
                'Default View': ["SHIPMENT NAME", "SUPPLIER", "FROM WAREHOUSE", "TO WAREHOUSE", "ORDER DATE", "ETA", "CREATED BY", "STATUS"],
            }
        },
        boardView: {
            status: {
                nameOfViews: [],
                allItemsName: ["Fully Received", "Seller Location", "Partially Received", "Cancelled", "Gated Out"],
                allViews: {
                    'Default View': ['Fully Received', 'Seller Location', 'Partially Received'],
                }
            },
            milestone: {
                nameOfViews: [],
                allItemsName: [],
                allViews: {

                }
            }
        },
    },

    singleView: {},
    milestoneOrders: {},
    milestoneList: {},
    filterShipmentByMilestone: {},
    consignmentMappedById: {},

    allFilterField: [],
    allFilterCondition: [],
    filteredConsignmentByRule: null,
    currentView: {
        purchase: null,
        shipment: null,
        transfer: null,

    }

};

const newInterfaceSlice = createSlice({
    name: "newInterface",
    initialState,
    reducers: {
        setMilestoneOrders: (state, action) => {
            state.milestoneList = action.payload;
        },
        handleAllViews: (state, action) => {
            const { nameOfView, listViews } = action.payload;
            if (nameOfView) {
                if (nameOfView === 'forAll') {
                    state.allViews = listViews;
                }
                else {
                    state.allViews = {
                        ...state.allViews,
                        [nameOfView]: listViews
                    }
                }
            }

            localStorage.setItem('allViews', JSON.stringify(state.allViews))
        },
        handleMilestoneOrders: (state, action) => {
            const { currentView, listOfMilestoneorders } = action.payload;
            state.milestoneOrders = {
                ...state.milestoneOrders,
                [currentView]: listOfMilestoneorders
            }
        },
        setAllViews: (state, action) => {
            const data = action.payload;
            const views = sortAllView(data);
            state.allViews = {
                listView: {
                    ...state.allViews.listView,
                    nameOfViews: views.list.nameOfList,
                    allViews: { ...state.allViews.listView.allViews, ...views.list.allViews }
                },
                boardView: {
                    status: {
                        ...state.allViews.boardView.status,
                        nameOfViews: views.status.nameOfList,
                        allViews: { ...state.allViews.boardView.status.allViews, ...views.status.allViews }
                    },
                    milestone: {
                        nameOfViews: views.milestone.nameOfList,
                        allViews: views.milestone.allViews
                    }
                },
                completeListOfViews: action.payload,
            }
        },
        setSingleView: (state, action) => {
            state.singleView = action.payload;
        },
        handleFilterShipmentByMilestone: (state, action) => {
            const { filterd, mapConsignments } = filterOrderByMilestone(action.payload, state.milestoneList)
            state.consignmentMappedById = mapConsignments;
            state.filterShipmentByMilestone = filterd;
        },
        updateFilterShipmentByMilestone: (state, action) => {
            const filterd = { ...state.filterShipmentByMilestone };

            filterd[action.payload.sourceIndex] = filterd[action.payload.sourceIndex].filter(item => item !== action.payload.data.ids[0]);
            filterd[action.payload.id] = [...action.payload.data.ids, ...filterd[action.payload.id]]

            state.filterShipmentByMilestone = filterd;
        },
        setAllFilterCondition: (state, action) => {
            state.allFilterCondition = action.payload
        },
        setAllFilterField: (state, action) => {
            state.allFilterField = action.payload;
        },
        setConsignmentsForRule: (state, action) => {
            state.filteredConsignmentByRule = action.payload;
        }

    },
});

export const {
    setMilestoneOrders,
    handleAllViews,
    handleMilestoneOrders,
    editMilestoneItem,
    setAllViews,
    setSingleView,
    handleFilterShipmentByMilestone,
    updateFilterShipmentByMilestone,
    setAllFilterCondition,
    setAllFilterField,
    setConsignmentsForRule,
} = newInterfaceSlice.actions;

export default newInterfaceSlice.reducer;
