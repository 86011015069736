import { useEffect, useMemo, useState } from 'react'
import { ListView } from '../components/ListView/ListView.jsx'
import { BoardView } from '../components/BoardView/BoardView.jsx'
import { CreateView } from '../components/CreateView/CreateView.jsx'
import { OptionsForView } from '../components/OptionsForView/OptionsForView.jsx'
import { BlurModal } from '../components/BlurModal/BlurModal.jsx'
import { useSelector } from 'react-redux'
import { useGetFilterConsignmentsByRuleQuery, useGetMilestonesQuery, useGetViewQuery } from '../../../../store/apiSlice/newInterfaceApiSlice.js'
import '../Shipment/Shipment.css'
import { useGetTransferOrdersQuery } from '../../../../store/apiSlice/orderApiSlice.js'
import { orderTypeForUrl } from '../utils/tableDataElements.js'
import { TopBar } from '../../components/TopBar/TopBar.jsx'

const TransferOrder = () => {
    const [shipmentOrders, setShipmentOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState([]);
    const [allOrderNames, setAllOrderNames] = useState([])
    const [isOpenCreateView, setisOpenCreateView] = useState(false);

    const orderType = useMemo(() => 'transfer', []);
    const currentViewFromLocal = JSON.parse(localStorage.getItem('currentView'));
    const [boardView, setBoardView] = useState(currentViewFromLocal ? (currentViewFromLocal[orderType]?.sub_type ? true : false) : false);
    const [selectedCurrentView, setSelectedCurrentView] = useState(currentViewFromLocal ? (currentViewFromLocal[orderType] ? currentViewFromLocal[orderType] : { value: 'Default View', sub_type: null }) : { value: 'Default View', sub_type: null })
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [filteredOrderByStatus, setFilteredOrderByStatus] = useState(null)
    const [filteredOrderByMilestone, setFilteredOrderByMilestone] = useState(null)
    const [editView, setEditView] = useState(false);
    const { transferOrders, transferOrdersParams } = useSelector(state => state.order);

    useGetMilestonesQuery();
    useGetTransferOrdersQuery(new URLSearchParams(transferOrdersParams).toString(), {
        refetchOnMountOrArgChange: true,
    });
    useGetViewQuery((new URLSearchParams(orderTypeForUrl['transfer']).toString()));
    const { refetch } = useGetFilterConsignmentsByRuleQuery(selectedCurrentView?.id, { skip: !selectedCurrentView.id })
    const { allViews: allViewsFromServer, milestoneList } = useSelector(state => state.newTransferOrder);

    const filterDataForOrderOfBoardView = (data) => {
        const filteredOrders = {};
        allViewsFromServer.boardView.status.allItemsName.forEach(item => {
            if (!filteredOrders[item]) filteredOrders[item] = [];
        })
        const milestoneMapWithShipment = { Uncategorized: [] };
        if (data && data.length > 0) {
            milestoneList?.forEach(item => {
                milestoneMapWithShipment[item.id] = []
            })

            data.forEach((order) => {
                if (order.milestone) {
                    milestoneMapWithShipment[order.milestone.id].push(order);
                } else {
                    milestoneMapWithShipment.Uncategorized.push(order)
                }
                filteredOrders[order.status].push(order)
            })
        }
        setFilteredOrderByStatus(filteredOrders)
        setFilteredOrderByMilestone(milestoneMapWithShipment);
    }

    const filterDataForOrderOfListView = (data) => {
        const allOrders = [];
        if (data && data.length > 0) {
            data.forEach((order) => {
                allOrders.push(order.name);
            })
            setShipmentOrders(data);
        } else {
            setShipmentOrders([]);
        }
        setAllOrderNames(allOrders);
    }

    useEffect(() => {
        filterDataForOrderOfListView(transferOrders.results)
        filterDataForOrderOfBoardView(transferOrders.results);
        // eslint-disable-next-line 
    }, [transferOrders.results, milestoneList]);

    useEffect(() => {
        if (selectedCurrentView.id && selectedCurrentView.isExistViewRule) {
            refetch()
        } else {
            boardView ?
                filterDataForOrderOfBoardView(transferOrders.results)
                :
                filterDataForOrderOfListView(transferOrders.results);
        }
        // eslint-disable-next-line 
    }, [selectedCurrentView.id])


    useEffect(() => {
        if (!selectedCurrentView.isExistViewRule) {
            filterDataForOrderOfListView(transferOrders.results)
            filterDataForOrderOfBoardView(transferOrders.results);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCurrentView.isExistViewRule])


    const handleSelectOrder = (e) => {
        if (e.target.checked) {
            setSelectedOrder([...selectedOrder, e.target.value])
        }
        else {
            setSelectedOrder(selectedOrder.filter(orderId => orderId !== e.target.value))
        }
    }

    const closeCreateView = () => {
        setisOpenCreateView(false);
        if (editView) setEditView(false);
    }

    return (
        <>
            <TopBar title='Transfer Orders' />
            <div className='shipment'>
                <OptionsForView selectedOrderLength={selectedOrder.length}
                    boardView={boardView}
                    setisOpenCreateView={setisOpenCreateView}
                    setSelectedCurrentView={setSelectedCurrentView}
                    selectedCurrentView={selectedCurrentView}
                    titleForRows={`Rows per page: ${rowsPerPage}`}
                    setRowsPerPage={setRowsPerPage}
                    setBoardView={setBoardView}
                    setEditView={setEditView}
                    getConsignmentByRule={refetch}
                    orderType="transfer"
                />

                {isOpenCreateView &&
                    <BlurModal>
                        <CreateView
                            closeCreateView={closeCreateView}
                            boardView={boardView}
                            editView={editView}
                            selectedCurrentView={selectedCurrentView}
                            orderType='transfer'
                        />
                    </BlurModal>
                }
                {
                    boardView ? <BoardView orderType='transfer' selectedCurrentView={selectedCurrentView} filteredOrderByStatus={filteredOrderByStatus} filteredOrderByMilestone={filteredOrderByMilestone} />
                        : <ListView
                            orderType='transfer'
                            allOrderNames={allOrderNames}
                            handleSelectOrder={handleSelectOrder}
                            rowsPerPage={rowsPerPage}
                            tableHeaderList={allViewsFromServer.listView.allViews[selectedCurrentView?.value]}
                            shipmentOrders={shipmentOrders} />
                }
            </div>
        </>
    )
}

export default TransferOrder 