import { ReactComponent as Clock } from '../../assets/svgIcon/listView/timer.svg'
import { showDateAndTime } from './showDateAndTime'

export const listDataElements = {

    shipment: (order, displayTime) => {
        const eta = showDateAndTime(order.eta, displayTime);
        const creationDate = showDateAndTime(order.date_of_creation, displayTime);
        return {
            STATUS: <td key='status' className='status'><span> <div className='statusBox'></div> {order.status} </span>  </td>,
            'ETA': <td key='eta' className='estimatedDate'><Clock /> {eta ? eta : 'No ETA'}</td>,
            'ORDER DATE': <td key='date_of_creation' className='estimatedDate'><Clock /> {creationDate ? creationDate : ''}</td>,
            'CREATED BY': <td key='creator' className='userPic'><div className='creatorName'>{order.created_by ? order.created_by.full_name : <span className="dataNotPresent">Not Available</span>}</div></td>,
            'FROM WAREHOUSE': <td key='from warehouse' className='wareHouse'>{order.from_warehouses.length ? order.from_warehouses[0].name : <span className="dataNotPresent">Not Applicable</span>}</td>,
            'TO WAREHOUSE': <td key='warehouse' className='wareHouse'>{order.to_warehouse}</td>,
            SUPPLIER: <td key='supplier' className='supplier'>{order.suppliers.length > 0 ? order.suppliers[0]['name'] : <span className="dataNotPresent">Not Applicable</span>}</td>,
            'SHIPMENT NAME': <td key='shipment name' className='supplier'>{order.reference ? order.reference : <span className="dataNotPresent">Not Available</span>}</td>,
        }
    },
    purchase: (order, displayTime) => {
        const eta = showDateAndTime(order.estimated_date, displayTime);
        const creationDate = showDateAndTime(order.create_date, displayTime);
        return {
            STATUS: <td key='status' className='status'><span> <div className='statusBox'></div> {order.status} </span>  </td>,
            'ETA DATE': <td key='eta' className='estimatedDate'><Clock /> {eta ? eta : 'No ETA'}</td>,
            'CREATE DATE': <td key='date_of_creation' className='estimatedDate'><Clock /> {creationDate ? creationDate : ''}</td>,
            'CREATED BY': <td key='creator' className='userPic'><div className='creatorName'>{order.creator ? order.creator : <span className="dataNotPresent">Not Available</span>}</div></td>,
            'WAREHOUSE': <td key='from warehouse' className='wareHouse'>{order.warehouse ? order.warehouse : <span className="dataNotPresent">Not Applicable</span>}</td>,
            SUPPLIER: <td key='supplier' className='supplier'>{order.supplier ? order.supplier : <span className="dataNotPresent">Not Applicable</span>}</td>,
            'PURCHASE NAME': <td key='shipment name' className='supplier'>{order.name ? order.name : <span className="dataNotPresent">Not Available</span>}</td>,
        }
    },
    transfer: (order, displayTime) => {
        const eta = showDateAndTime(order.estimated_date, displayTime);
        const creationDate = showDateAndTime(order.create_date, displayTime);
        return {
            STATUS: <td key='status' className='status'><span> <div className='statusBox'></div> {order.status} </span>  </td>,
            'CONSOLIDATION STATUS': <td key='status consildation' className='status'><span> <div className='statusBox'></div> {order.consolidation_status} </span>  </td>,
            'ETA': <td key='eta' className='estimatedDate'><Clock /> {eta ? eta : 'No ETA'}</td>,
            'CREATE DATE': <td key='date_of_creation' className='estimatedDate'><Clock /> {creationDate ? creationDate : ''}</td>,
            'CREATED BY': <td key='creator' className='userPic'><div className='creatorName'>{order.creator ? order.created_by.full_name : <span className="dataNotPresent">Not Available</span>}</div></td>,
            'FROM WAREHOUSE': <td key='from warehouse' className='wareHouse'>{order.from_warehouses ? order.from_warehouses : <span className="dataNotPresent">Not Applicable</span>}</td>,
            'TO WAREHOUSE': <td key='warehouse' className='wareHouse'>{order.to_warehouse}</td>,
            'ERP SUPPLIER': <td key='supplier' className='supplier'>{order.erp_supplier ? order.erp_supplier : <span className="dataNotPresent">Not Applicable</span>}</td>,
        }
    },
}

export const listOfClasses = {
    'STATUS': 'statusHeader', 'ETA DATE': 'estimatedDate', 'ETA': 'estimatedDate', 'ORDER DATE': 'estimatedDate', 'CREATED BY': 'userPic',
    'WAREHOUSE': 'wareHouse', 'FROM WAREHOUSE': 'wareHouse', 'TO WAREHOUSE': 'wareHouse', 'SUPPLIER': 'supplier', 'SHIPMENT NAME': 'supplier',
    'ERP SUPPLIER': 'supplier'
};

export const orderTypeForUrl = {
    "purchase": {
        module: "purchase_order"
    },
    "shipment": {
        module: "consignment"
    },
    "transfer": {
        module: "transfer_order"
    },
}

export const selectState = {
    "purchase": "newPurchaseOrder",
    "shipment": "newInterface",
    "transfer": "newTransferOrder",
}

export const selectStateForOrder = {
    "purchase": "purchaseOrder",
    "shipment": "shipment",
    "transfer": "order",
}
export const selectStateForNotes = {
    "purchase": "purchaseOrder",
    "shipment": "consignment",
    "transfer": "transferOrder",
}
export const headerForFilter = {
    "purchase": "Purchase",
    "shipment": "Shipment",
    "transfer": "Transfer",
}


export const colorByStatus = {
    'Fully Received': 'green', 'Seller Location': 'sky', 'Partially Received': 'orange', 'Cancelled': 'red',
}