import { ListIcon } from '../../../assets/svgIcon/listView/ListIcon'
import { BoardIcon } from '../../../assets/svgIcon/listView/Board'
import { Eye } from '../../../assets/svgIcon/listView/Eye'
import { ReactComponent as MenuIcon } from '../../../assets/svgIcon/shipment/Menu.svg'
import { SelectComponent } from '../SelectComponent/SelectComponent.jsx'
import { ViewRules } from '../ViewRules/ViewRules.jsx'
import { BlurModal } from '../BlurModal/BlurModal.jsx'
import { Button } from '../Button/Button.jsx'
import { useState } from 'react'
import { Milestone } from '../../../components/Milestone/Milestone.jsx'
import { GeneralModal } from '../../../components/GeneralModal/GeneralModal.jsx'
import { useCreateFilterCriteriaMutation, useDeleteFilterCriteriaMutation, useUpdateFilterCriteriaMutation } from '../../../../../store/apiSlice/newInterfaceApiSlice.js'
import { useDispatch, useSelector } from 'react-redux'
import { setNotification } from '../../../../../store/slice/miscSlice.js'
import { selectState } from '../../utils/tableDataElements.js'

export function OptionsForView({ boardView, setBoardView, selectedOrderLength, titleForRows, setRowsPerPage, setisOpenCreateView, selectedCurrentView, setSelectedCurrentView, setEditView, getConsignmentByRule, orderType }) {
    const rowsPerPage = ['All', '10 rows', '20 rows', '40 rows', '60 rows', '100 rows'];
    const [isOpenMilestone, setIsOpenMilestone] = useState(false);
    const [isOpenViewRules, setIsOpenViewRules] = useState(false);
    const [editPattern, setEditPattern] = useState(1);
    const [createViewRule] = useCreateFilterCriteriaMutation();
    const [updateViewRule] = useUpdateFilterCriteriaMutation();
    const [deleteViewRule] = useDeleteFilterCriteriaMutation();
    const [filterCriteriaForCurrentView, setFilterCriteriaForCurrentView] = useState(null);
    const { completeListOfViews } = useSelector(state => state[selectState[orderType]].allViews);
    const dispatch = useDispatch();

    const initialState = {
        field: "",
        condition: "",
        value: "",
        criteria: null,
        index: 1
    }
    const [dropDownValues, setDropDownValues] = useState([initialState]);

    const openViewRules = () => {
        if (selectedCurrentView.value === "Default View") {
            dispatch(setNotification({
                type: "info",
                message: "Default view active. Switch to a custom view to set up a view rule!"
            }));
            return;
        }
        const view = completeListOfViews.find(item => item.id === selectedCurrentView.id);
        if (view && view.filter_criteria) {
            setFilterCriteriaForCurrentView(view.filter_criteria);

            const patternArr = view.filter_criteria.pattern.split(" ").filter(item => item === "OR" || item === "AND");
            let viewValue = view.filter_criteria.filter.map((item, ind) => {
                return {
                    field: item.field.id,
                    condition: item.condition.id,
                    value: item.value,
                    index: item.index,
                    criteria: patternArr.length === 0 ? null : patternArr[ind]
                }
            })
            setDropDownValues(viewValue);
            if (patternArr.length > 0) setEditPattern(view.filter_criteria.pattern)
        }
        setIsOpenViewRules(true);

    }

    const closeViewRules = () => {
        setIsOpenViewRules(false);
        setDropDownValues([initialState]);
        setEditPattern(1)
    }

    const openMileStoneView = () => {
        setIsOpenMilestone(true);
    }
    const closeMilestoneModal = () => {
        setIsOpenMilestone(false);
    }

    const handleListView = () => {
        setBoardView(false);
        setSelectedCurrentView({ value: 'Default View', sub_type: null })
    }
    const handleBoardView = () => {
        setBoardView(true);
        setSelectedCurrentView({ value: 'Default View', sub_type: null })
    }

    const handleViewRules = async (val) => {
        const data = {
            filter: dropDownValues,
            pattern: editPattern,
            view: selectedCurrentView.id
        }
        closeViewRules();
        setSelectedCurrentView({ ...selectedCurrentView, isExistViewRule: true });
        if (selectedCurrentView.isExistViewRule) {
            await updateViewRule({ data, id: filterCriteriaForCurrentView.id })
        } else {
            await createViewRule(data)
        }
        getConsignmentByRule();


    }
    const resetViewRules = async () => {
        if (filterCriteriaForCurrentView) {
            await deleteViewRule(filterCriteriaForCurrentView.id);
            setFilterCriteriaForCurrentView(null);
            setSelectedCurrentView({ ...selectedCurrentView, isExistViewRule: false });
            setDropDownValues([initialState])
        }
    }

    return (
        <div className='options'>
            {
                isOpenViewRules &&
                <BlurModal >
                    <GeneralModal closeModal={closeViewRules} titleOfModal='View Rules' widthOfGeneralModal='65vw'>
                        <ViewRules dropDownValues={dropDownValues} setDropDownValues={setDropDownValues} initialState={initialState}
                            editPattern={editPattern} setEditPattern={setEditPattern} />

                        <div className="buttonGroup">
                            <Button btnName="Apply" handler={handleViewRules} />
                            <Button btnName="Reset" handler={resetViewRules} isDisabledButton={!Boolean(filterCriteriaForCurrentView)} />
                        </div>
                    </GeneralModal>
                </BlurModal>
            }

            <nav className={selectedOrderLength ? 'leftSide activeState' : 'leftSide'}>
                <ul>
                    <li className={`tableButton ${selectedCurrentView.isExistViewRule ? 'isAppliedRule' : 'activeState'}`} onClick={openViewRules}>Rules </li>
                    <li className='tableButton'>Cancel Shipment </li>
                </ul>
            </nav>

            <nav className="rightSide">
                <div className='selectOptions'>
                    <Milestone isOpenMilestone={isOpenMilestone} openMilestoneModal={openMileStoneView} closeMilestoneModal={closeMilestoneModal} orderType={orderType} />
                    <div className={isOpenMilestone ? 'milestoneBtn activeMilestone' : 'milestoneBtn'} onClick={openMileStoneView}>Milestones</div>

                    <SelectComponent boardView={boardView} setisOpenCreateView={setisOpenCreateView} selectedCurrentView={selectedCurrentView}
                        setSelectedCurrentView={setSelectedCurrentView} title={selectedCurrentView.value} LeftIcon={Eye}
                        setEditView={setEditView} orderType={orderType}
                    />

                    <SelectComponent setRowsPerPage={setRowsPerPage} title={titleForRows} data={rowsPerPage} selectedCurrentView={selectedCurrentView} orderType={orderType} />
                </div>

                <div className='viewIcon'>
                    <button className={boardView ? 'listIcon' : 'listIcon activeIcon'} onClick={handleListView}>
                        <ListIcon color={boardView ? '#8C8CA2' : `var(--DARK-BLUE, #1249BF)`} />
                    </button>
                    <button className={boardView ? 'boardIcon activeIcon' : 'boardIcon'} onClick={handleBoardView}>
                        <BoardIcon color={boardView ? `var(--DARK-BLUE, #1249BF)` : '#8C8CA2'} />
                    </button>
                </div>

                <span className='menuIcon'><MenuIcon /></span>
            </nav>
        </div>
    )
}
