import { useState } from 'react';
import { BlurModal } from '../../page/components/BlurModal/BlurModal'
import { GeneralModal } from '../GeneralModal/GeneralModal'

export const DeleteModal = ({ closeDeleteModal, handleDeleteModal, nameOfView }) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleChange = (e) => {
        setIsChecked(e.target.checked)
    }
    return (
        <BlurModal>
            <GeneralModal isChecked={isChecked} titleOfModal='Delete View' btnName='Delete' closeModal={closeDeleteModal}
                handleModalWork={handleDeleteModal} widthOfGeneralModal='30vw'>
                <p>Think carefully! Deleting this "{nameOfView}" milestone is permanent, and the data will be lost forever.</p>

                <label className='confirmDeletion'><input type="checkbox" onChange={handleChange} checked={isChecked} />
                    I understand that the data cannot be retrieved after deleting.</label>
            </GeneralModal>
        </BlurModal>
    )
}
