import { useState } from 'react'
import { FilterModal } from './FilterModal/FilterModal';
import { ReactComponent as UpDown } from '../../../assets/svgIcon/listView/up-down-arrow.svg'
import { ReactComponent as FilterIcon } from '../../../assets/svgIcon/listView/filter.svg'
import { ReactComponent as Close } from '../../../assets/svgIcon/listView/Close.svg'
import { Link } from 'react-router-dom';
import { headerForFilter, listDataElements, listOfClasses } from '../../utils/tableDataElements';
import { useSelector } from 'react-redux';

export function ListView({ allOrderNames, rowsPerPage, shipmentOrders, tableHeaderList, handleSelectOrder, orderType }) {
  const [filteredOrder, setFilteredOrder] = useState([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const { displayTime } = useSelector(state => state.misc.settings);

  const tableDataElements = (order) => {
    const allElements = listDataElements[orderType](order, displayTime);

    return tableHeaderList.map(header => (
      allElements[header.toUpperCase()]
    ))
  }

  const filterOrderByName = (filtered) => {
    setFilteredOrder(shipmentOrders.filter(order => {
      return filtered.find(name => name === order.name)
    }))
  }

  const clearFilter = () => {
    setFilteredOrder([]);
  }

  const closeFilterModal = () => {
    setIsFilterModalOpen(false)
  }

  return (
    <div className='listView'>
      <table>
        <thead>
          <tr >
            <th className='checkBox'><input type="checkbox" /></th>
            <th className='projectName'>
              <UpDown />
              {orderType.toUpperCase()} NO
              {filteredOrder.length ? <span title='Clear filter' onClick={clearFilter}>{filteredOrder.length} <Close /></span>
                : <FilterIcon onClick={() => setIsFilterModalOpen(true)} />
              }
              {isFilterModalOpen && <FilterModal title={headerForFilter[orderType]} closeFilterModal={closeFilterModal} filterOrderByName={filterOrderByName} allOrderNames={allOrderNames}
                placeholderForInput={`${headerForFilter[orderType]} No`} />}
            </th>

            {tableHeaderList?.map((item) => {
              const header = item.toUpperCase();
              return (
                <th key={header} className={listOfClasses[header]}>
                  <UpDown />
                  {header}
                  {header !== 'WAREHOUSE' && <FilterIcon />}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody onClick={handleSelectOrder}>
          {tableHeaderList && (filteredOrder.length ? filteredOrder : shipmentOrders).slice(0, rowsPerPage).map((order, ind) => {
            return (
              <tr key={order.id}>
                <td className='checkBox'><input type="checkbox" value={order.id} /></td>
                {orderType === "shipment" && <td className='projectName'><Link to={`${order.id}`}> {order.name}</Link></td>}
                {orderType === "purchase" && <td className='projectName'><Link to={`detail/${order.id}`}> {order.name}</Link></td>}
                {orderType === "transfer" && <td className='projectName'><Link to={`${order.id}`}> {order.name}</Link></td>}

                {tableDataElements(order, tableHeaderList)}
              </tr>
            )
          }
          )}
        </tbody>
      </table>
    </div>
  )
}
