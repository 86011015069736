import { format } from "date-fns"
import { TrackingInfo } from "../TrackingInfo/TrackingInfo";
import { NotesModal } from "../Notes/Notes";
import { colorNames } from "../../../components/Milestone/constant"
import { GeneralModal } from "../../../components/GeneralModal/GeneralModal";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as TrackIcon } from '../../../assets/svgIcon/shipment/track.svg'
import { ReactComponent as NotesIcon } from '../../../assets/svgIcon/shipment/Notes.svg'
import { BlurModal } from "../BlurModal/BlurModal";

export const StatusBoardView = ({ status, filteredOrderByStatus, ind, orderType }) => {
    const [isTrackModalOpen, setIsTrackModalOpen] = useState(false);
    const [isOpenNotes, setIsOpenNotes] = useState(false)
    const [orderId, setorderId] = useState(null)
    const [orderIdForNotes, setOrderIdForNotes] = useState(null);

    const openTrackModal = (id, orderName, trackData) => {
        setIsTrackModalOpen(true);
        setorderId({ id: id, name: orderName, trackInfo: trackData })
    }
    const closeTrackModal = () => {
        setIsTrackModalOpen(false)
        setorderId(null)
    }
    const openNotesModal = (id, orderName, is_note_available) => {
        setOrderIdForNotes({ id, name: orderName, is_note_available })
        setIsOpenNotes(true)
    }
    const closeNotesModal = () => {
        setIsOpenNotes(false)
        setOrderIdForNotes(null)
    }

    return (
        <div key={status} className="board">
            <div className="boardHeader">
                <h3 className={`colorBox ${colorNames[ind]}`}><p className='tickBox'></p> <p>{status}</p> </h3>
                <p className='countNo'>{filteredOrderByStatus?.length}</p>
            </div>
            <div className="tilesContainer">
                {isTrackModalOpen &&
                    <BlurModal >
                        <GeneralModal widthOfGeneralModal='20vw' closeModal={closeTrackModal} titleOfModal={`Tracking Information: ${orderId.name}`} >
                            <TrackingInfo orderId={orderId.id} trackInfoFromParent={orderId.trackInfo} orderType={orderType} />
                        </GeneralModal>
                    </BlurModal>
                }
                {
                    isOpenNotes &&
                    <BlurModal>
                        <GeneralModal widthOfGeneralModal={'50vw'} titleOfModal={`Notes: ${orderIdForNotes.name}`} closeModal={closeNotesModal} >
                            <NotesModal key={orderIdForNotes.id} orderId={orderIdForNotes.id} isNoteAvailable={orderIdForNotes.is_note_available} orderType={orderType} />
                        </GeneralModal>
                    </BlurModal>
                }

                {
                    filteredOrderByStatus &&
                    filteredOrderByStatus.map(order => (
                        <div key={order.id} className='boardTile'>
                            <div className='tileHeader'>
                                <div className="left">
                                    <input className='checkBox' type="checkbox" />
                                    <Link to={`/shipment/international/${order.id}`} >{order.name} </Link>
                                </div>
                                <div className="btnGroup">
                                    <span className={` ${order.tracking ? 'iconInBoat' : ''} iconWrapper`} title="Tracking Info" onClick={() => openTrackModal(order.id, order.name, order.tracking)}><TrackIcon /> </span>
                                    <span className={` ${order.is_note_available ? 'iconInBoat' : ''} iconWrapper`} title="Notes" onClick={() => openNotesModal(order.id, order.name, order.is_note_available)}><NotesIcon /> </span>
                                </div>
                            </div>
                            <div className="orderInfo">
                                <p>To Warehouse: {order.to_warehouse}</p>
                                <p>Company: Mainfreight</p>
                                <p>ASN number: 6002 </p>
                            </div>
                            <p className='eta'>ETA: {order.eta || order.estimated_date ? format(order.eta || order.estimated_date, 'dd MMM yyyy, hh:mm a') : 'No eta '}</p>
                        </div>
                    ))
                }

            </div>
        </div>
    )
}
