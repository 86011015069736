import styled from "styled-components"
import { Button } from "../Button/Button"
import { ReactComponent as UpDown } from '../../../assets/svgIcon/listView/up-down-arrow.svg'
import { ReactComponent as FilterIcon } from '../../../assets/svgIcon/listView/filter.svg'
import { ReactComponent as Close } from '../../../assets/svgIcon/listView/Close.svg'
import { listOfClasses, rowDataOfTable, tableHeaderList, totalCountForData, tableHeaderListIncludeTF, countMapWithHeader, allOrderName, checkZeroOrNot } from "./constant"
import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { EditColumn } from "./EditColumn/EditColumn"
import { BlurModal } from "../BlurModal/BlurModal"
import { GeneralModal } from "../../../components/GeneralModal/GeneralModal"
import { headerForFilter } from "../../utils/tableDataElements"
import { FilterModal } from "../ListView/FilterModal/FilterModal"
import { TrackingStatus } from "./TrackingStatus"
import { useGetPurchaseOrderStatusQuery } from "../../../../../store/apiSlice/purchaseOrderApiSlice"

export const PurchaseOrderLines = ({ po_line = [], orderType }) => {
  const allOrderNames = allOrderName[orderType](po_line);
  const [selectedPoLine, setselectedPoLine] = useState([]);
  const [isShowTransferOrder, setIsShowTransferOrder] = useState(false);
  const { displayTime } = useSelector(state => state.misc.settings);
  const [isEditColumn, setIsEditColumn] = useState(false);
  const [sortedPoline, setSortedPoline] = useState(po_line);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState({
    product: false,
    status: false,
    supplier: false,
    orderNumber: false,
    orderedQty: false,
  });
  const [filteredOrder, setFilteredOrder] = useState(null);
  const [isTrackStatusOpen, setIsTrackStatusOpen] = useState(false);
  const [containerNoForTrack, setContainerNoForTrack] = useState(false);
  const [nameOfAppliedFilter, setNameOfAppliedFilter] = useState(null)

  useGetPurchaseOrderStatusQuery()
  const status = useSelector(state => state.purchaseOrder.purchaseOrderStatus)
  const openTrackingStatusModal = (containerNo) => {
    setContainerNoForTrack(containerNo)
    setIsTrackStatusOpen(true)
  }
  const closeTrackingStatusModal = () => {
    setIsTrackStatusOpen(false);
    setContainerNoForTrack(null)
  }

  let totalCount = useMemo(() => {
    const selectDataForCount = filteredOrder ? filteredOrder : po_line;
    return totalCountForData[orderType](selectDataForCount)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredOrder, po_line])

  const handleOpenEditColumn = () => {
    setIsEditColumn(true);
  }
  const closeEditColumn = () => {
    setIsEditColumn(false)
  }

  const tableHeaderListSelected = isShowTransferOrder ? tableHeaderListIncludeTF : tableHeaderList[orderType];
  const [headerList, setHeaderList] = useState(tableHeaderListSelected.map(item => { return { columnName: item, checked: true } }));
  const selectedHeaderList = headerList.reduce((acc, item) => {
    if (item.checked)
      acc.push(item.columnName)
    return acc;
  }, []);

  useEffect(() => {
    setHeaderList(tableHeaderListSelected.map(item => { return { columnName: item, checked: true } }))
    // eslint-disable-next-line
  }, [isShowTransferOrder]);

  useEffect(() => {
    setSortedPoline(po_line);
  }, [po_line])

  const handleSelectRow = (e) => {
    if (e.target.checked) {
      setselectedPoLine([...selectedPoLine, Number(e.target.value)])
    }
    else {
      setselectedPoLine(selectedPoLine.filter(item => item !== Number(e.target.value)))
    }
  }
  const handleSelectAllRow = (e) => {
    if (e.target.checked) {
      setselectedPoLine(po_line.map(item => item.id))
    }
    else {
      setselectedPoLine([])
    }
  }

  const toggleTransferOrder = () => {
    setIsShowTransferOrder(!isShowTransferOrder);

  }
  const handleSortPoline = (header) => {
    const arr = [...po_line];

    if (header === 'STATUS') {
      arr.sort((a, b) => a['status'].localeCompare(b['status']));
    }
    else if (header === "ETA") {
      arr.sort((a, b) => new Date(a['eta']) - new Date(b['eta']));
    }
    else if (header === "ATA") {
      arr.sort((a, b) => new Date(a['ata']) - new Date(b['ata']));
    }
    else if (header === 'TF ORD') {
      arr.sort((a, b) => a.tf_ord.name.localeCompare(b.tf_ord.name));
    } else if (header === "PRODUCT NO") {
      if (orderType === 'shipment') {
        arr.sort((a, b) => a.line.product.localeCompare(b.line.product));
      } else {
        arr.sort((a, b) => a.product.number.localeCompare(b.product.number));
      }
    }
    setSortedPoline(arr)
  }


  const filterOrderByProduct = (filtered) => {
    const filterByData = po_line.filter(order => {
      const productNo = order.line ? order.line.product : order.product.number;
      return filtered.find(name => name === productNo)
    })
    totalCount = totalCountForData[orderType](filterByData)
    setFilteredOrder(filterByData);
  }
  const filterOrderByOrderNo = (filtered) => {
    const filterByData = po_line.filter(order => {
      return filtered.find(name => name === order.line?.order_no)
    })
    totalCount = totalCountForData[orderType](filterByData)
    setFilteredOrder(filterByData);
  }

  const filterOrderByQty = (filtered, nameOfQty) => {
    const filterByData = po_line.filter(order => {
      return filtered.find(name => {
        return checkZeroOrNot[nameOfQty](name, order);
      }
      )
    })
    totalCount = totalCountForData[orderType](filterByData)
    setFilteredOrder(filterByData);
  }
  const filterOrderByStatus = (filtered) => {
    const filterByData = po_line.filter(order => {
      return filtered.find(name => name === order.status)
    })
    totalCount = totalCountForData[orderType](filterByData)
    setFilteredOrder(filterByData);
  }

  const dataForQuantity = ['Zero', 'Non zero']

  const clearFilter = () => {
    setFilteredOrder(null);
    setNameOfAppliedFilter(null)
  }

  const openFilterModal = (item) => {
    setIsFilterModalOpen({ ...isFilterModalOpen, [item]: true });
  }
  const closeFilterModal = (item) => {
    setIsFilterModalOpen({ ...isFilterModalOpen, [item]: false })
    setNameOfAppliedFilter(item)
  }

  return (
    <Container>
      {
        isEditColumn &&
        <BlurModal>
          <GeneralModal titleOfModal="Edit Columns" closeModal={closeEditColumn} widthOfGeneralModal="70vw">

            <EditColumn headerList={headerList} setHeaderList={setHeaderList} closeEditColumn={closeEditColumn} />
          </GeneralModal>
        </BlurModal>
      }
      {isTrackStatusOpen && <TrackingStatus containerNo={containerNoForTrack} closeModal={closeTrackingStatusModal} />}
      <nav className="headerList">
        {orderType === 'purchase' &&
          <Button btnName={`${isShowTransferOrder ? 'Hide' : "Show"} Transfer Orders`} classOfBtn="headerItem" handler={toggleTransferOrder} />
        }
        <Button btnName="Edit Columns" classOfBtn="headerItem" handler={handleOpenEditColumn} />
        <Button btnName="Update ETA" classOfBtn="headerItem" handler={() => ''} isDisabledButton={selectedPoLine.length > 0 ? false : true} />
        <Button btnName="Receipt" classOfBtn="headerItem" handler={() => ''} isDisabledButton={selectedPoLine.length > 0 ? false : true} />
      </nav>
      <div className="tableOfOrderLines listView">
        <table>
          <thead>
            <tr>
              <th className='checkBox'><input type="checkbox" onChange={handleSelectAllRow} /></th>
              <th className='projectName '>

                <UpDown onClick={() => handleSortPoline('PRODUCT NO')} />
                PRODUCT NO
                {nameOfAppliedFilter === 'product' && filteredOrder ? <span title='Clear filter' className="clearFilter" onClick={clearFilter}>{filteredOrder.length} <Close /></span>
                  : <FilterIcon onClick={() => openFilterModal('product')} />
                }
                {isFilterModalOpen.product && <FilterModal title={headerForFilter[orderType]} closeFilterModal={() => closeFilterModal('product')} filterOrderByName={filterOrderByProduct} allOrderNames={allOrderNames['product']}
                  placeholderForInput={`Search ${headerForFilter[orderType]} No`} />}
              </th>

              {selectedHeaderList?.map((item) => {
                const header = item.toUpperCase();
                return (
                  <th
                    key={header}
                    className={listOfClasses[item]}
                  >
                    {(header === 'STATUS' || header === "ETA" || header === "TF ORD" || header === "ATA") && <UpDown onClick={() => handleSortPoline(header)} />}
                    {header}
                    {header === 'STATUS' && (filteredOrder && nameOfAppliedFilter === 'status' ? <span className="clearFilter" title='Clear filter' onClick={clearFilter}>{filteredOrder.length} <Close /></span>
                      : <FilterIcon onClick={() => openFilterModal('status')} />)
                    }
                    {header === "STATUS" && isFilterModalOpen.status && <FilterModal title={headerForFilter[orderType]} closeFilterModal={() => closeFilterModal('status')} filterOrderByName={filterOrderByStatus} allOrderNames={status}
                      placeholderForInput={`Select Status `} />}


                    {header === 'ORDER NO' && (filteredOrder && nameOfAppliedFilter === 'orderNumber' ? <span className="clearFilter" title='Clear filter' onClick={clearFilter}>{filteredOrder.length} <Close /></span>
                      : <FilterIcon onClick={() => openFilterModal('orderNumber')} />)
                    }
                    {header === "ORDER NO" && isFilterModalOpen.orderNumber && <FilterModal title={"Order"} closeFilterModal={() => closeFilterModal('orderNumber')} filterOrderByName={filterOrderByOrderNo} allOrderNames={allOrderNames['orderNumber']}
                      placeholderForInput={`Search Order No `} />}


                    {header === 'SUPPLIER' && (filteredOrder && nameOfAppliedFilter === 'supplier' ? <span className="clearFilter" title='Clear filter' onClick={clearFilter}>{filteredOrder.length} <Close /></span>
                      : <FilterIcon onClick={() => openFilterModal('supplier')} />)
                    }
                    {header === "SUPPLIER" && isFilterModalOpen.supplier && <FilterModal title={"Supplier"} closeFilterModal={() => closeFilterModal('supplier')} filterOrderByName={filterOrderByStatus} allOrderNames={allOrderNames['supplier']}
                      placeholderForInput={`Select Supplier `} />}


                    {header === 'ORDERED QTY' && (filteredOrder && nameOfAppliedFilter === 'orderedQty' ? <span className="clearFilter" title='Clear filter' onClick={clearFilter}>{filteredOrder.length} <Close /></span>
                      : <FilterIcon onClick={() => openFilterModal('orderedQty')} />)
                    }
                    {header === "ORDERED QTY" && isFilterModalOpen.orderedQty && <FilterModal title={"Order Qty"} closeFilterModal={() => closeFilterModal('orderedQty')} filterOrderByName={filterOrderByQty} allOrderNames={dataForQuantity}
                      placeholderForInput={`Select Qty `} />}


                    {header === 'RECEIPTED QTY' && (filteredOrder && nameOfAppliedFilter === 'recievedQty' ? <span className="clearFilter" title='Clear filter' onClick={clearFilter}>{filteredOrder.length} <Close /></span>
                      : <FilterIcon onClick={() => openFilterModal('recievedQty')} />)
                    }
                    {header === "RECEIPTED QTY" && isFilterModalOpen.recievedQty && <FilterModal title={"Received Qty"} closeFilterModal={() => closeFilterModal('recievedQty')} filterOrderByName={filterOrderByQty} allOrderNames={dataForQuantity}
                      placeholderForInput={`Select Qty `} />}


                    {header === 'DISPATCHED QTY' && (filteredOrder && nameOfAppliedFilter === 'dispatchedQty' ? <span className="clearFilter" title='Clear filter' onClick={clearFilter}>{filteredOrder.length} <Close /></span>
                      : <FilterIcon onClick={() => openFilterModal('dispatchedQty')} />)
                    }
                    {header === "DISPATCHED QTY" && isFilterModalOpen.dispatchedQty && <FilterModal title={"Dispatched Qty"} closeFilterModal={() => closeFilterModal('dispatchedQty')} filterOrderByName={filterOrderByQty} allOrderNames={dataForQuantity}
                      placeholderForInput={`Select Qty `} />}


                    {header === 'ALLOCATED QTY' && (filteredOrder && nameOfAppliedFilter === 'allocatedQty' ? <span className="clearFilter" title='Clear filter' onClick={clearFilter}>{filteredOrder.length} <Close /></span>
                      : <FilterIcon onClick={() => openFilterModal('allocatedQty')} />)
                    }
                    {header === "ALLOCATED QTY" && isFilterModalOpen.allocatedQty && <FilterModal title={"Allocated Qty"} closeFilterModal={() => closeFilterModal('allocatedQty')} filterOrderByName={filterOrderByQty} allOrderNames={dataForQuantity}
                      placeholderForInput={`Select Qty `} />}

                  </th>
                )
              })}
            </tr>
          </thead>

          <tbody>
            <>
              {
                sortedPoline.length > 0 && (filteredOrder ? filteredOrder : sortedPoline).map(line => (
                  <tr key={line.id}>
                    <td className='checkBox'><input type="checkbox" onChange={handleSelectRow} checked={selectedPoLine.includes(line.id)} value={line.id} /></td>
                    <td className='projectName'>{orderType === "shipment" ? line.line.product : line.product.number} </td>
                    {rowDataOfTable(line, displayTime, orderType, selectedHeaderList, openTrackingStatusModal)}
                  </tr>

                ))
              }

              {<tr className={`total moreItems`}>
                <td className="forCheckBox"></td>
                <td className="projectName"></td>
                {
                  selectedHeaderList.map(item => {
                    if (item === "ETA") {
                      return <td key={item} className="estimatedDate"> Total</td>;
                    }
                    return <td key={item} className={listOfClasses[item]} >{totalCount[countMapWithHeader[item]] && totalCount[countMapWithHeader[item]].toFixed(2)} </td>
                  })
                }
              </tr>}
            </>
          </tbody>

        </table>
      </div>
    </Container>
  )
}

const Container = styled.div`
    margin: 24px;
    margin-bottom: 0;
    flex: 1;
    display: flex;
    flex-direction: column;

    >.nameOfLine{
        color: #0E0E2C;
        font-size: 16px;
        font-weight: 600;
        line-height: 120%; 
    }

    .headerItem{
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        background-color: var(--MIST, #f7f8f9);
        color: var(--BLUE, #2060EA);
    }
    >.headerList{
        display: flex;
        gap: 16px;
        margin-bottom: 8px;
    }
    >.tableOfOrderLines{
      flex: 1;
      width: 100%;
      overflow-x: auto;
    }

    table,
    thead,
    tbody {
      width: 100%;
      font-size: 14px;
    }
     table {
      min-width: max-content;
      height: 100%;

      tr{
        table-layout: fixed;
      }
      tbody, thead{
        >tr{
            .uom{
                width: 4vw;
            }
            .forCheckBox{
                width: 46px;
            }
            >td, th{
              font-weight: 400;
            }
            .tfToWarehouse{
              width: 11vw;
            }
            .totalCost{
              width: 7vw;
            }
            .ata{
              width: 4vw;
            }
            .dispatched{
              width: 10vw;

            }
        }
      }
      > thead > tr {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 34px;

        > th {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 8px 10px;
          color: #4a4a68;
          font-weight: 700;
          font-size: 12px;
          line-height: 18px;

          svg {
            cursor: pointer;
          }
        }
        
        .totalCost{
          margin-right: 14px;
        }
        .subTotal{
          margin-right: 22px;
        }
        > .checkBox {
          padding: 8px 10px 8px 20px;

          > input {
            width: 16px;
            height: 16px;
          }
        }
        .quantity{
            width: 9vw;
        }
        .smallWidth{
            width: 6vw;
        }
        .projectName {
          padding: 8px 10px 8px 22px;
          min-width: 200px;
          
        }
        .supplier, .orderNo, .projectName, .quantity, .dispatched{
          position: relative;

        }
         .clearFilter {
          display: flex;
          padding: 0px 8px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 100px;
          background: var(--Peach, #f3d9da);
          cursor: pointer;
        }
        .statusHeader {
          width: 11vw;
          position: relative;
        }
        .estimatedDate {
          width: 11rem;
        }
      }
      > tbody {
        overflow-y: auto;
        height: 100%;
        scrollbar-width: thin;
        display: block;
        > tr {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 48px;
          font-size: 14px;
          /* font-weight: 500; */
          line-height: 20px;

          > td {
            padding: 12px 10px;
          }

          > .checkBox {
            display: flex;
            padding: 8px 10px 8px 20px;

            > input {
              width: 16px;
              height: 16px;
            }
          }

          .projectName {
            min-width: 200px;
            padding-left: 22px;
            color: #1249bf;
            cursor: pointer;
            font-weight: 500;
            a {
              color: #1249bf;
            }
          }
          
        .quantity{
            width: 9vw;
        }
        .smallWidth{
            width: 6vw;
        }
        .statusHeader{
          width: 11vw;
        }

        .status {
            width: 11vw;
            font-size: 12px;
            line-height: 18px;

            span {
              display: flex;
              align-items: center;
              gap: 6px;
              background-color: #e1fcef;
              padding: 0 8px;
              width: max-content;
              font-weight: 500;

              .statusBox {
                width: 6px;
                height: 6px;
                background-color: #38a06c;
                border-radius: 2px;
              }
            }
          }
          .estimatedDate {
            width: 11rem;
            font-weight: 400;
            display: flex;
            align-items: center;
            gap: 6px;
          }
          .container{
            font-size: 12px;
            font-weight: 400;
            line-height: 22px;
            background-color: var(--MIST, #f7f8f9);
            color: var(--BLUE, #2060EA);
            width: max-content;
          }
          
        }
        >.total{
          font-size: 14px;
          font-weight: 600;
          border-top: 1px solid var(--Color-Tokens-Border-Primary, #E4E4E4);
          background-color: var(--CLOUD, #FCFDFE);
          .status{
              text-align: center;
              font-size: 14px;
              font-weight: 600;
          }
        }
        >.moreItems{
          position: sticky;
          bottom: 0;
        }
        >.containerBtn{
          display: flex;
          justify-content: end;
        }
      }
    }
`