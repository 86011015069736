import { baseURL } from "../../constant";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logOutUser } from "../slice/utils";

// A default base query with credentials included
const baseQuery = fetchBaseQuery({
  baseUrl: baseURL,
  credentials: "include",
});

/**
 * Enhanced base query with reauthentication logic.
 * If a request returns a 401 error due to an invalid token,
 * this function tries to refresh the token. If the token refresh is successful,
 * it retries the original request. If the token refresh fails,
 * the user will be logged out.
 *
 */
const baseQueryWithReauth = async (args, api, extraOptions) => {
  const TOKEN_NOT_VALID = "token_not_valid";
  let result = await baseQuery(args, api, extraOptions);

  // Check for a specific 'token not valid' error
  if (result?.error?.status === 401 && result?.error?.data?.code === TOKEN_NOT_VALID) {
    // Attempt to refresh the token
    const isRefreshSuccessful = await baseQuery("api_user/refresh_token/", api, extraOptions);

    // If token refresh was successful, retry the original request
    if (isRefreshSuccessful.meta?.response?.status === 200) {
      result = await baseQuery(args, api, extraOptions);
    } else {
      // If token refresh failed, dispatch a logout action to clear user session
      api.dispatch(logOutUser());
    }
  }

  return result;
};

// A RTK Query API slice with the custom base query for handling reauthentication
export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({}),
  tagTypes: ["allocatedPurchaseList", "allocatedTransferList", "consignmentUpdateInfo", "milestonesUpdateList", "viewUpdate", "consignmentList", "filteredConsignmentList", "purchaseOrderList", "transferOrderList", "transferOrderList"],
});
