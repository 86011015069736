import { useSelector } from 'react-redux';
import { InputText } from '../../../components/InputText/InputText';
import { DropDown, SearchDropDown } from '../DropDown/DropDown';
import { useEffect, useState } from 'react';
import { statusWithId } from '../../Shipment/Constant/Constant';
import styled from 'styled-components';

export const FilterForOneField = ({ ind, setDropDownValues, handleAddMoreCriteria, dropDownValues }) => {
    const { allFilterCondition, allFilterField } = useSelector(state => state.newInterface);
    const [filteredCondition, setFilteredCondition] = useState(allFilterCondition);
    const [isStatusDropDownOpen, setIsStatusDropDownOpen] = useState(false)
    const [selectedStatusItems, setSelectedStatusItems] = useState(dropDownValues[ind] === 3 ? dropDownValues[ind].value : [])

    useEffect(() => {
        const filteredField = allFilterField.find(item => item.id === dropDownValues[ind]['field']);

        if (filteredField)
            setFilteredCondition(allFilterCondition.filter(item => filteredField.conditions.includes(item.id)));

        if (dropDownValues[ind]['field'] === 3) {
            setSelectedStatusItems(dropDownValues[ind].value)
        }
        // eslint-disable-next-line  
    }, [dropDownValues[ind]['field']])


    const handleInputValue = (e) => {
        setDropDownValues(state => {
            const allData = state.map((item, indexOfItem) => indexOfItem === ind ? { ...item, value: e.target.value } : item);
            return allData;
        })
    }

    const handleSelectStatusOption = (e) => {
        let items = [...selectedStatusItems];
        if (e.target.checked) {
            items.push(e.target.value)
        }
        else {
            items = items.filter(item => item !== e.target.value)
        }

        setSelectedStatusItems(items);
        const allValues = [...dropDownValues];
        allValues[ind]['value'] = items;
        setDropDownValues(allValues);
    }

    return (
        <div key={ind} className='filterForColumn search-dropdown-container'>
            <SearchDropDown index={ind} handleDropDown={setDropDownValues} isObject={true} allData={allFilterField} isSelected={allFilterField.find(item => item.id === dropDownValues[ind].field)?.name} fieldName="Field" />
            <SearchDropDown index={ind} handleDropDown={setDropDownValues} isObject={true} allData={filteredCondition} isSelected={filteredCondition.find(item => item.id === dropDownValues[ind].condition)?.name} fieldName="Condition" />
            {dropDownValues[ind]['field'] === 3 ?
                <DropDown className="search-dropdown-container">
                    <label className="search-dropdown-label">Value</label>
                    <div
                        className="search-dropdown-header"
                        onClick={() => setIsStatusDropDownOpen(!isStatusDropDownOpen)}
                    >
                        {selectedStatusItems.length > 0 ? 'Selected' : 'Select'}
                        <span className="search-dropdown-arrow">&#9662;</span>
                    </div>
                    {isStatusDropDownOpen &&
                        <StatusDropDown statusWithId={statusWithId} handleSelectStatusOption={handleSelectStatusOption} dropDownValues={dropDownValues[ind].value} />
                    }
                </DropDown>
                :
                <InputText labelOfInput="Value" placeholder="Type value here" text={dropDownValues[ind].value} setText={handleInputValue} />
            }
            <SearchDropDown index={ind} handleDropDown={setDropDownValues} options={["OR", "AND"]} fieldName="Criteria" handleAddMoreCriteria={handleAddMoreCriteria} isSelected={dropDownValues[ind].criteria} />
        </div>
    )
}

export const StatusDropDown = ({ statusWithId, handleSelectStatusOption, dropDownValues }) => {
    return <div className="search-dropdown-menu">
        <ul className="search-dropdown-options">
            {statusWithId.map((item) => (
                <LabelForStatus className='search-dropdown-option' key={item.id} >
                    <input type="checkbox"
                        onChange={handleSelectStatusOption}
                        checked={dropDownValues ? dropDownValues.some(id => id === item.id) : false}
                        value={item.id} />
                    {item.name}
                </LabelForStatus>
            ))}

        </ul>

    </div>

}

const LabelForStatus = styled.label`
    justify-content: start !important;
    gap: 8px;

`