import { useEffect, useMemo, useState } from 'react'
import { ListView } from '../components/ListView/ListView.jsx'
import { BoardView } from '../components/BoardView/BoardView.jsx'
import { CreateView } from '../components/CreateView/CreateView.jsx'
import { OptionsForView } from '../components/OptionsForView/OptionsForView.jsx'
import { BlurModal } from '../components/BlurModal/BlurModal.jsx'
import { useSelector } from 'react-redux'
import { useGetConsignmentsQuery } from '../../../../store/apiSlice/shipmentApiSlice.js'
import { useGetFilterConsignmentsByRuleQuery, useGetMilestonesQuery, useGetViewQuery } from '../../../../store/apiSlice/newInterfaceApiSlice.js'
import './Shipment.css'
import { orderTypeForUrl } from '../utils/tableDataElements.js'
import { TopBar } from '../../components/TopBar/TopBar.jsx'

const Shipment = () => {
  const [shipmentOrders, setShipmentOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [allOrderNames, setAllOrderNames] = useState([])
  const [isOpenCreateView, setisOpenCreateView] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const orderType = useMemo(() => 'shipment', []);
  const currentViewFromLocal = JSON.parse(localStorage.getItem('currentView'));
  const statusOfViewFromLocal = currentViewFromLocal ? (currentViewFromLocal[orderType]?.sub_type ? true : false) : false;
  const [boardView, setBoardView] = useState(statusOfViewFromLocal);
  const [selectedCurrentView, setSelectedCurrentView] = useState(currentViewFromLocal ? (currentViewFromLocal[orderType] ? currentViewFromLocal[orderType] : { value: 'Default View', sub_type: null }) : { value: 'Default View', sub_type: null })
  const [filteredOrderByStatus, setFilteredOrderByStatus] = useState(null)
  const [filteredOrderByMilestone, setFilteredOrderByMilestone] = useState(null)
  const [editView, setEditView] = useState(false);
  const { consignments, consignmentsParams } = useSelector(state => state.shipment);

  useGetMilestonesQuery();
  useGetConsignmentsQuery(new URLSearchParams(consignmentsParams).toString(), {
    refetchOnMountOrArgChange: true,
  });
  useGetViewQuery((new URLSearchParams(orderTypeForUrl['shipment']).toString()));
  const { refetch } = useGetFilterConsignmentsByRuleQuery(selectedCurrentView.id, { skip: !selectedCurrentView.id })
  const { allViews: allViewsFromServer, milestoneList, filteredConsignmentByRule } = useSelector(state => state.newInterface);

  const filterDataForOrderOfBoardView = (data) => {
    const filteredOrders = {};
    data.forEach(item => {
      if (!filteredOrders[item.status]) filteredOrders[item.status] = [];
    })
    const milestoneMapWithShipment = { Uncategorized: [] };
    if (data && data.length > 0) {
      milestoneList?.forEach(item => {
        milestoneMapWithShipment[item.id] = []
      })

      data.forEach((order) => {
        if (order.milestone) {
          milestoneMapWithShipment[order.milestone.id].push(order);
        } else {
          milestoneMapWithShipment.Uncategorized.push(order)
        }
        filteredOrders[order.status].push(order)
      })
    }
    setFilteredOrderByStatus(filteredOrders)
    setFilteredOrderByMilestone(milestoneMapWithShipment);
  }

  const filterDataForOrderOfListView = (data) => {
    const allOrders = [];
    if (data && data.length > 0) {
      data.forEach((order) => {
        allOrders.push(order.name);
      })
      setShipmentOrders(data);
    } else {
      setShipmentOrders([]);
    }
    setAllOrderNames(allOrders);
  }

  useEffect(() => {
    filterDataForOrderOfListView(consignments.results)
    filterDataForOrderOfBoardView(consignments.results);
    // eslint-disable-next-line 
  }, [consignments.results, milestoneList]);

  useEffect(() => {
    if (selectedCurrentView.sub_type && filteredConsignmentByRule) {
      filterDataForOrderOfBoardView(filteredConsignmentByRule)
    } else {
      filterDataForOrderOfListView(filteredConsignmentByRule)
    }
    // eslint-disable-next-line 
  }, [filteredConsignmentByRule])

  useEffect(() => {
    if (selectedCurrentView.id && selectedCurrentView.isExistViewRule) {
      refetch()
    } else {
      boardView ?
        filterDataForOrderOfBoardView(consignments.results)
        :
        filterDataForOrderOfListView(consignments.results);
    }
    // eslint-disable-next-line 
  }, [selectedCurrentView.id])

  useEffect(() => {
    if (!selectedCurrentView.isExistViewRule) {
      filterDataForOrderOfListView(consignments.results)
      filterDataForOrderOfBoardView(consignments.results);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrentView.isExistViewRule])



  const handleSelectOrder = (e) => {
    if (e.target.checked) {
      setSelectedOrder([...selectedOrder, e.target.value])
    }
    else {
      setSelectedOrder(selectedOrder.filter(orderId => orderId !== e.target.value))
    }
  }

  const closeCreateView = () => {
    setisOpenCreateView(false);
    if (editView) setEditView(false);
  }

  return (
    <>
      <TopBar title='International Shipments' />
      <div className='shipment'>
        <OptionsForView selectedOrderLength={selectedOrder.length}
          boardView={boardView}
          setisOpenCreateView={setisOpenCreateView}
          setSelectedCurrentView={setSelectedCurrentView}
          selectedCurrentView={selectedCurrentView}
          titleForRows={`Rows per page: ${rowsPerPage}`}
          setRowsPerPage={setRowsPerPage}
          setBoardView={setBoardView}
          setEditView={setEditView}
          getConsignmentByRule={refetch}
          orderType={orderType}
        />

        {isOpenCreateView &&
          <BlurModal>
            <CreateView
              closeCreateView={closeCreateView}
              boardView={boardView}
              editView={editView}
              selectedCurrentView={selectedCurrentView}
              orderType={orderType}
              setSelectedCurrentView={setSelectedCurrentView}
            />
          </BlurModal>
        }
        {
          boardView ? <BoardView orderType={orderType} selectedCurrentView={selectedCurrentView} filteredOrderByStatus={filteredOrderByStatus} filteredOrderByMilestone={filteredOrderByMilestone} />
            : <ListView
              orderType={orderType}
              allOrderNames={allOrderNames}
              handleSelectOrder={handleSelectOrder}
              rowsPerPage={rowsPerPage}
              tableHeaderList={allViewsFromServer.listView.allViews[selectedCurrentView?.value]}
              shipmentOrders={shipmentOrders} />
        }
      </div>
    </>
  )
}

export { Shipment }