import { useSelector } from "react-redux";
import { MilestoneBoard } from "../MilestoneBoard/MilestoneBoard";
import { StatusBoardView } from "../Board/Board";
import { selectState } from "../../utils/tableDataElements";

export function BoardView({ selectedCurrentView, filteredOrderByStatus, filteredOrderByMilestone, orderType }) {
    const { status: { allViews: listOfStatusViews }, milestone: { allViews: listOfMilestoneViews } } = useSelector(state => state[selectState[orderType]].allViews.boardView)

    return (
        <div className='boardView'>
            {selectedCurrentView.sub_type === 'milestone' && listOfMilestoneViews[selectedCurrentView.value]?.length > 0 ?
                <MilestoneBoard selectedCurrentView={selectedCurrentView} filteredOrderByMilestone={filteredOrderByMilestone} orderType={orderType} />
                :
                listOfStatusViews[selectedCurrentView.value]?.length && listOfStatusViews[selectedCurrentView.value].map((status, ind) => (
                    <StatusBoardView key={status} filteredOrderByStatus={filteredOrderByStatus ? filteredOrderByStatus[status] : []} status={status} ind={ind} orderType={orderType} />
                ))
            }
        </div>
    )
}




