export const statusWithId = [
    {
        "id": "seller_location",
        "name": "Seller Location"
    },
    {
        "id": "gated_out",
        "name": "Gated Out"
    },
    {
        "id": "partially_received",
        "name": "Partially Received"
    },
    {
        "id": "fully_received",
        "name": "Fully Received"
    },
    {
        "id": "cancelled",
        "name": "Cancelled"
    }
]